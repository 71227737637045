body {
  font-family: "roboto";
  margin: 0;
}

div.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

div.mainContent {
  margin-top: 80px;
  margin-bottom: 100px;
  padding: 15px;
  flex: 1;
}

div.threeCols {
  display: grid;
  grid-gap: 5%;
  grid-template-columns: repeat(3, [col] 30%);
}

div.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
}

div.filterContainer {
  display: block;
  width: 100%;
}

div.filter {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 10px;
  margin-bottom: 30px;
}

.filterField {
  width: 45%;
}

@media only screen and (max-width: 800px) {
  div.filter {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }

  .filterField {
    width: 100%;
    padding: 15px 0;
  }
}

div.gridCard {
  width: 100%;
  height: 110px;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

div.colorBar {
  width: 5px;
  height: 100%;
}

div.blue {
  background-color: #03A9F4;
}

div.green {
  background-color: #8BC34A;
}

div.red {
  background-color: #FF5722;
}

div.gridCardHeader {
  width: 100%;
  padding: 10px;
}

div.gridRow {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-left: 10px;
  padding-right: 10px;
}

div.gridColumn {
  width: 100%;
  padding-right: 10px;
  font-size: 12px;
}

div.center {
  text-align: center;
}

div.horizontalSpacer {
  margin-bottom: 10px;
}

